import { FC } from 'react';

import { addDays } from 'date-fns';

import { getLastSubscriptionInvoiceAttempt } from '~/modules/subscriptions/utils/getLastSubscriptionInvoiceAttempt';
import { getLastSubscriptionInvoice } from '~/modules/subscriptions/utils/getLastSubscriptionInvoice';
import { SubscriptionPaymentTypeEnum } from '~/modules/subscriptions/enums/SubscriptionPaymentTypeEnum';
import { SubscriptionInvoiceStatusEnum } from '~/modules/subscriptions/enums/SubscriptionInvoiceStatusEnum';
import { ICompany } from '~/modules/companies/interfaces/ICompany';
import { useCompanies } from '~/modules/companies/hooks/companies';
import { useAuth } from '~/modules/auth/hooks/auth';

import { formatDate } from '~/shared/utils/formatDate';
import { routes } from '~/shared/constants/routes';

import { Container } from './styles';

const formatOverdueCompanyText = (
  company: ICompany,
  companiesLength: number
): string => {
  const lastInvoice = getLastSubscriptionInvoice(company.subscription.invoices);

  const endText = `Clique aqui e realize o pagamento até o dia ${formatDate(
    addDays(new Date(lastInvoice.billingDate), 10),
    false
  )} ou sua conta será bloqueada.`;

  if (lastInvoice.paymentType === SubscriptionPaymentTypeEnum.boleto) {
    return `${
      companiesLength === 1
        ? 'A sua fatura venceu'
        : `A fatura da empresa ${company.name} venceu`
    }. ${endText}`;
  }

  return `Não foi possivel realizar a cobrança ${
    companiesLength === 1
      ? 'da sua fatura'
      : `da fatura da empresa ${company.name}`
  }  em 3 tentativas. ${endText}`;
};

export const OverdueSubscriptionsWarning: FC = () => {
  const { companies } = useCompanies();
  const { user } = useAuth();

  const overdueCompanies = companies.filter((company) => {
    if (Object.values(company.subscription || {}).length === 0) return false;

    const lastInvoice = getLastSubscriptionInvoice(
      company.subscription.invoices
    );

    if (!lastInvoice) return false;

    if (
      lastInvoice.paymentType === SubscriptionPaymentTypeEnum.boleto &&
      lastInvoice.status === SubscriptionInvoiceStatusEnum.overdue
    ) {
      return true;
    }

    const lastInvoiceAttempt = getLastSubscriptionInvoiceAttempt(
      lastInvoice.attempts
    );

    if (!lastInvoiceAttempt) return false;

    if (
      lastInvoice.paymentType === SubscriptionPaymentTypeEnum.credit_card &&
      lastInvoice.attempts.length === 3 &&
      lastInvoiceAttempt.status === SubscriptionInvoiceStatusEnum.rejected
    ) {
      return true;
    }

    return false;
  });

  if ((overdueCompanies || []).length === 0) return null;

  if (user?.companiesWithoutContract?.length > 0) {
    return <></>;
  }

  return (
    <>
      {overdueCompanies.map((company, index) => (
        <Container
          key={company._id}
          href={routes.subscriptions.details(company.subscription._id)}
          index={index}
        >
          <span>
            {formatOverdueCompanyText(company, overdueCompanies.length)}
          </span>
        </Container>
      ))}
    </>
  );
};
