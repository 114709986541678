import { FC } from 'react';

import { HiMenuAlt2 } from 'react-icons/hi';

import { Notifications } from '~/modules/notifications/components/Notifications';
import { HelpDropdown } from '~/modules/help/components/HelpDropdown';
import { FavoriteApps } from '~/modules/favoriteApps/components/FavoriteApps';
import { CoursesDropdown } from '~/modules/courses/components/CoursesDropdown';
import { BalanceInfo } from '~/modules/balance/components/BalanceInfo';
import { useAuth } from '~/modules/auth/hooks/auth';

import { useToolbar } from '~/shared/hooks/toolbar';
import { useSideBarDrawer } from '~/shared/hooks/sideBarDrawer';

import { ToolbarIconButton } from './ToolbarIconButton';
import { Container, LeftContent, ActionsWrapper } from './styles';
import { MyAccount } from './MyAccount';

export const Toolbar: FC = () => {
  const { isSmallerThan850, toggle, isOpen } = useSideBarDrawer();
  const { leftContent } = useToolbar();
  const { user } = useAuth();

  if (user?.companiesWithoutContract?.length > 0) {
    return <></>;
  }

  return (
    <Container id="toolbar">
      <div>
        <LeftContent>
          {isSmallerThan850 && (
            <ToolbarIconButton
              tooltip={isOpen ? 'Fechar menu' : 'Abrir menu'}
              icon={HiMenuAlt2}
              onClick={toggle}
            />
          )}

          {leftContent}
        </LeftContent>

        <ActionsWrapper>
          {!isSmallerThan850 && (
            <>
              <BalanceInfo />
            </>
          )}

          <HelpDropdown />

          <CoursesDropdown />

          <Notifications />

          <FavoriteApps />

          <MyAccount />
        </ActionsWrapper>
      </div>
    </Container>
  );
};
